.posts-container {
  padding: 1%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.post-wrapper {
  width: 30%;
  margin: 0.5%;
}
.img-container {
  width: 98%;
}
.post-img {
  width: 100%;
  display: block;
  border-radius: 1rem;
}
.post-date {
  width: 98%;
  padding: 1% 1% 1% 0;
  text-align: left;
}
.post-title {
  width: 98%;
  padding: 1% 1% 1% 0;
  text-align: left;
}
.post-body {
  width: 98%;
  padding: 1% 1% 1% 0;
  text-align: left;
}
.share-btn {
  width: 99.9%;
  padding: 1% 1% 1% 0;
  text-align: left;
}
