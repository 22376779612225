.contests-section {
  margin: 0 auto;
  padding: 2%;
}
table.table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}
.table th,
.table td {
  border-collapse: collapse;
  border: 1px solid var(--dark-text-color);
  text-align: left;
  padding: 0.5%;
  width: 33%;
}
.th-contest {
  background-color: var(--highlight-pink);
  color: var(--white);
}
.th-start,
.th-end {
  background-color: var(--highlight-pink);
  color: var(--white);
}
.td-contest {
  color: var(--dark-text-color);
  font-size: 0.8rem;
  font-weight: 600;
}
.td-contest a {
  text-decoration: none;
  color: var(--dark-text-color);
}
.td-contest a:hover {
  color: var(--highlight-pink);
}
.td-start {
  font-size: 0.8rem;
}
.td-end {
  font-size: 0.8rem;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  table.table {
    width: 90%;
  }
  .table th,
  .table td {
    font-size: 0.4rem;
  }
}
