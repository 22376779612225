.custom-learn-section {
  width: 90%;
  margin: 0 auto;
}
.learn-icons {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}
.html-icon,
.css-icon,
.js-icon,
.react-icon {
  height: 110px;
  width: 110px;
  margin: 2%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 1px 2px 1px;
}
.html-icon {
  background-image: url('./html-pink.svg');
}
.html-icon:hover {
  background-image: url('./html-color.svg');
}
.css-icon {
  background-image: url('./css-pink.svg');
}
.css-icon:hover {
  background-image: url('./css-color.svg');
}
.js-icon {
  background-image: url('./js-pink.svg');
}
.js-icon:hover {
  background-image: url('./js-color.svg');
}
.react-icon {
  background-image: url('./react-pink.svg');
}
.react-icon:hover {
  background-image: url('./react-color.svg');
}
.html-icon:hover,
.css-icon:hover,
.js-icon:hover,
.react-icon:hover {
  cursor: pointer;
  opacity: 0.9;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .html-icon,
  .css-icon,
  .js-icon,
  .react-icon {
    height: 50px;
    width: 50px;
  }
}
