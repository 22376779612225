.suggest-company {
  margin: 0 auto;
  margin-bottom: 4%;
  border: 1px solid var(--highlight-pink);
  border-radius: 1rem;
  box-shadow: var(--highlight-pink) 0px 1px 5px;
}
h2 {
  font-size: 1.3rem;
  margin-top: 0.5%;
  padding: 1%;
}
p {
  margin-bottom: 1%;
  padding: 0.5 1%;
}
.company-form {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 3%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.company-form-label {
  width: 70%;
  margin-top: 0.7%;
  text-align: left;
}
.company-form-input {
  width: 70%;
  margin-top: 0.5%;
  padding: 1%;
  border: 1px solid var(--black);
}
.company-form-submit {
  width: 70%;
  margin: 0 auto;
  margin-top: 2%;
  cursor: pointer;
  padding: 1.2%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
.company-form-submit:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
  border: 1px solid var(--white);
}
/*Small devices*/
@media screen and (max-width: 640px) {
  h2 {
    font-size: 1rem;
    padding: 3% 5% 0 5%;
  }
  p {
    font-size: 0.8rem;
    padding: 3% 5% 0% 5%;
  }
  .company-form {
    width: 100%;
  }
  .company-form-label {
    width: 80%;
    padding: 1%;
    font-size: 0.8rem;
  }
  .company-form-input {
    width: 80%;
    font-size: 0.8rem;
    margin-top: 0;
  }
  .company-form-submit {
    width: 80%;
  }
}
