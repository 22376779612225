.footer-container {
  background-color: var(--black);
}
.footer-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3%;
  justify-content: space-between;
  background-color: var(--black);
}
.footer-wrapper p {
  display: flex;
  color: var(--white);
}
.footer-wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: var(--white);
}
.footer-wrapper a:hover {
  color: var(--highlight-orange);
}
.so-me-icons {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  align-items: center;
}
.icon-ig,
.icon-in,
.icon-github {
  display: block;
  padding: 5%;
  margin-left: 5px;
  margin-right: 5px;
  border: 2px solid var(--white);
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  opacity: 0.9;
}
.icon-ig:hover,
.icon-in:hover,
.icon-github:hover {
  border: 2px solid var(--highlight-pink);
  opacity: 1;
}
.icon-container:hover {
  border: 2px solid var(--highlight-pink);
  opacity: 1;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .footer-wrapper p {
    font-size: 0.6rem;
  }
  .footer-wrapper a {
    font-size: 0.7rem;
  }
  .icon-ig,
  .icon-in,
  .icon-github {
    width: 20px;
    padding: 3%;
    margin-left: 3px;
    margin-right: 3px;
  }
}
/*Medium (641px to 1007px)*/
@media screen and (min-width: 641px) and (max-width: 1007px) {
  .footer-wrapper p {
    font-size: 0.8rem;
  }
  .footer-wrapper a {
    font-size: 0.9rem;
  }
  .icon-ig,
  .icon-in,
  .icon-github {
    width: 20px;
    padding: 3%;
    margin-left: 3px;
    margin-right: 3px;
  }
}
