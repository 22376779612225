.counter-wrapper {
  margin: 0% auto;
  padding: 2.5% 1%;
  margin-bottom: 2%;
}
.counter-text {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.8rem;
  color: var(--dark-text-color);
}
.btn-container {
  margin: 0 auto;
}
.counter-btn {
  margin: 0 auto;
  display: block;
  padding: 0.55em 0.7em;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--dark-text-color);
  margin-top: 0.3rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  font-size: 0.8rem;
  cursor: pointer;
}
.counter-btn:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
}
