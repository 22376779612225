.resources {
  margin: 0 auto;
  background-color: var(--white);
}
.active-learn-icon {
  display: block;
  width: 13%;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
}
.resources h1 {
  margin: 0 auto;
  margin-top: 1%;
  padding-top: 1%;
  text-align: center;
}
.html-h1 {
  border-top: 5px solid var(--html-orange);
}
.css-h1 {
  border-top: 5px solid var(--css-blue);
}
.js-h1 {
  border-top: 5px solid var(--js-yellow);
}
.react-h1 {
  border-top: 5px solid var(--react-blue);
}
.resource-cards-wrapper {
  padding: 2.5% 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.html-resource-card,
.css-resource-card,
.js-resource-card,
.react-resource-card {
  width: 20%;
  min-width: 300px;
  margin: 1% 0.5%;
  background-color: var(--white);
  color: var(--white);
  border: 3px solid var(--dark-text-color);
  border-radius: 1rem;
  box-shadow: var(--dark-text-color) 0px 5px 15px;
  overflow: hidden;
}
.html-resource-card:hover {
  border: 3px solid var(--html-orange);
  box-shadow: var(--html-orange) 0px 5px 15px;
}
.html-resource-card .resource-details {
  background-color: var(--html-orange);
}
.css-resource-card:hover {
  border: 3px solid var(--css-blue);
  box-shadow: var(--css-blue) 0px 5px 15px;
}
.css-resource-card .resource-details {
  background-color: var(--css-blue);
}
.js-resource-card:hover {
  border: 3px solid var(--js-yellow);
  box-shadow: var(--js-yellow) 0px 5px 15px;
}
.js-resource-card .resource-details {
  background-color: var(--js-yellow);
}
.react-resource-card:hover {
  border: 3px solid var(--react-blue);
  box-shadow: var(--react-blue) 0px 5px 15px;
}
.react-resource-card .resource-details {
  background-color: var(--react-blue);
}
.resource-header {
  background-color: var(--white);
  color: var(--dark-text-color);
  margin: 0 auto;
  padding: 2% 3%;
  text-align: center;
}
.resource-name {
  color: var(--dark-text-color);
  text-decoration: none;
  padding-bottom: 1%;
  font-size: 1.2rem;
}
.resource-details {
  font-size: 0.85rem;
  padding: 2%;
  text-align: center;
}
.resource-content {
  padding: 4%;
}
.img-container {
  display: block;
  position: relative;
  width: 100%;
  position: relative;
  background-color: var(--white);
}
.resource-img {
  display: block;
  position: relative;
  width: 100%;
}
.resource-content a {
  text-decoration: none;
}
.video-description {
  position: absolute;
  top: 20%;
  left: 5%;
  box-shadow: var(--dark-text-color) 0px 1px 5px;
  margin: 0 auto;
  margin-bottom: 4%;
  background-color: rgba(255, 255, 255, 0.8);
  width: 90%;
  padding: 5%;
  border-radius: 1rem;
  max-height: 160px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2;
  overflow: auto;
  text-align: center;
  color: var(--dark-text-color);
}
.resource-description {
  box-shadow: var(--dark-text-color) 0px 1px 5px;
  margin: 0 auto;
  margin-bottom: 4%;
  background-color: rgba(255, 255, 255, 0.8);
  width: 90%;
  padding: 5%;
  border-radius: 1rem;
  max-height: 160px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2;
  overflow: auto;
  text-align: center;
  color: var(--dark-text-color);
}
