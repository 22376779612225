/*Login*/
.login-form {
  width: 90%;
  margin: 0 auto;
  padding: 0.5% 0 1% 0;
  font-size: 0.9rem;
}
.login-form p {
  padding: 0;
  margin-bottom: 0.2%;
}
.login-input {
  border: 1px solid gray;
  padding: 0.3%;
  margin-right: 0.5%;
}
.login-btn {
  padding: 0.5% 1%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: var(--highlight-pink) 0px 1px 5px;
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
/*User bar*/
.welcome-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 0.9rem;
}
/*Welcome*/
.welcome-sentence {
  width: 90%;
  padding: 0.5%;
  font-size: 1.1rem;
  margin-bottom: 0;
}
/*Random Quote*/
.random-quote {
  width: 90%;
  opacity: 0.85;
}
.quote {
  padding-top: 0;
  font-weight: 600;
  color: var(--highlight-pink);
}
.author {
  padding-top: 0;
  font-style: italic;
  color: var(--highlight-pink);
}
/*User Status*/
.status-wrapper {
  width: 90%;
  margin: 1% 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.status-question {
  width: 100%;
  padding: 0 0.5%;
  margin-bottom: 0;
}
.status-msg {
  width: 100%;
  padding: 0 0.5%;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}
/*Buttons*/
.status-btn-wrapper {
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.status-btn {
  margin: 1% 0.5% 0.2% 0.5%;
  padding: 0.5% 2%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: var(--highlight-pink) 0px 1px 5px;
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
.status-btn:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
  border: 1px solid var(--white);
}
.logout {
  width: 90%;
  margin-bottom: 2%;
}
.logout-btn {
  margin: 0 auto;
  padding: 0.5% 1%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: var(--highlight-pink) 0px 1px 5px;
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
.login-btn:hover,
.logout-btn:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
  border: 1px solid var(--white);
}
