.filter-by {
  width: 90%;
  margin: 0 auto;
  margin-top: 1%;
}
.select-topic {
  margin: 0 auto;
  margin-top: 1%;
  padding: 0.5%;
  border: 1px solid var(--black);
  border-radius: 1rem;
  box-shadow: var(--highlight-pink) 0px 1px 5px;
}
.company-cards-wrapper {
  padding: 2.5% 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.company-card {
  width: 20%;
  min-width: 300px;
  margin: 1% 0.5%;
  background-color: var(--dark-text-color);
  border: 3px solid var(--dark-text-color);
  box-shadow: rgba(204, 195, 187, 0.35) 0px 5px 15px;
  border-radius: 1rem;
  color: var(--black);
  overflow: hidden;
}
.company-card:hover {
  border: 3px solid var(--highlight-pink);
  background-color: var(--highlight-pink);
  cursor: pointer;
}
.company-header {
  background-color: var(--white);
  margin: 0 auto;
  padding: 2%;
  text-align: center;
}
.company-name {
  color: var(---light-black9);
  text-decoration: none;
  padding-bottom: 1%;
  font-size: 1.2rem;
}
.company-country {
  font-size: 0.9rem;
}
.company-details {
  color: var(--white);
  font-size: 0.85rem;
  padding: 2%;
  text-align: center;
}
.img-container {
  position: relative;
  max-height: 200px;
}
.company-img {
  display: block;
  position: relative;
  width: 100%;
  min-height: 200px;
}
.company-description {
  position: absolute;
  top: 10%;
  left: 5%;
  background-color: rgba(255, 255, 255, 0.8);
  width: 90%;
  padding: 5%;
  border-radius: 1rem;
  max-height: 160px;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.2;
  overflow: auto;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .company-card {
    margin: 2%;
  }
}
