:root {
  --highlight-pink: #f615ba;
  --highlight-yellow: #ffbe0b;
  --highlight-orange: #ffa400;
  --light-black8: rgba(0, 0, 0, 0.8);
  --dark-text-color: rgba(0, 0, 0, 0.9);
  --white: #fbfcfa;
  --black: #14110f;
  --html-orange: #f06529;
  --css-blue: #264de4;
  --js-yellow: #f7df1e;
  --react-blue: #61dbfb;
  /* pink rgb(246,21,186) */
  /* white rgb rgb(251,252,250) */

  --highlight-aqua: #79f1df;
  --dark-aqua: #00c2a4;
  --aqua-rgba65: rgba(86, 251, 219, 0.65);
  --aqua-rgba90: rgba(86, 251, 219, 0.9);
  --light-text-color: #969a97;
}

* {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding, 80px);
}

body {
  /* color: var(--dark-text-color); */
  background-color: var(--white);
}
