.navbar {
  width: 100%;
  background: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.3%;
  padding-bottom: 0.3%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.logo-container {
  cursor: pointer;
  margin-left: 5%;
}
.logo {
  width: 35%;
  padding: 1% 0;
  display: block;
}
.pages-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 5%;
}
.link-container {
  margin-left: 1rem;
  margin-top: 1px;
  border-top: 3px solid var(--highlight-pink);
  text-align: center;
  cursor: pointer;
}
.link-container:hover {
  border-top: 3px solid var(--highlight-orange);
}
.router-link {
  text-decoration: none;
  color: var(--white);
  font-weight: 500;
}
.link:hover {
  opacity: 0.8;
}
.link:active {
  color: var(--highlight-orange);
}
/*Small (smaller than 640px)*/
@media screen and (max-width: 640px) {
  .navbar {
    align-items: flex-start;
  }
  .logo-container {
    width: 30%;
    padding-top: 3%;
  }
  .logo {
    width: 80%;
  }
  .pages-wrapper {
    margin-top: 3%;
    flex-direction: column;
  }
  .link-container {
    border-top: 2px solid var(--highlight-pink);
  }
}
