.toggle-resource-btns-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.toggle-resource-btn {
  margin: 0.5%;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 1%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  font-size: small;
  cursor: pointer;
}
.toggle-resource-btn:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
  border: 1px solid var(--white);
}
