.contests-btn {
  margin: 0.5%;
  padding: 1%;
  border: 1px solid var(--dark-text-color);
  border-radius: 1rem;
  background-color: var(--white);
  color: var(--dark-text-color);
  text-decoration: none;
  text-align: center;
  transition: all 0.15s;
  font-size: small;
  cursor: pointer;
}
.contests-btn:hover {
  background-color: var(--dark-text-color);
  color: var(--white);
  opacity: 1;
}
