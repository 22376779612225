.hero-container {
  position: relative;
}
.hero-bg-img {
  display: block;
  position: relative;
  height: 100vh;
  width: 100%;
  object-fit: cover;
  opacity: 1;
}
.hero-text {
  position: absolute;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  top: 25%;
  left: 10%;
  color: var(--highlight-pink);
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.hero-text h1 {
  font-size: 3rem;
  margin-bottom: 0.2rem;
}
.hero-text h2 {
  font-size: 2rem;
  color: var(--white);
}
.hero-btn {
  display: inline-block;
  padding: 0.5em 3em;
  border: 3px solid rgba(251, 252, 250, 0.9);
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--white);
  margin-top: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
.hero-btn:hover {
  background-color: rgba(255, 164, 0, 0.75);
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .hero-text h1 {
    font-size: 2rem;
    margin-bottom: 0.2rem;
  }
  .hero-text h2 {
    font-size: 1rem;
    color: var(--white);
  }
}
