.contact-section {
  color: var(--dark-text-color);
  text-align: center;
}
.contact-header-container h1 {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
  color: var(--highlight-pink);
  font-size: 3rem;
  text-transform: uppercase;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.contact-body {
  width: 90%;
  margin: 0 auto;
}
.contact-form {
  width: 80%;
  margin: 3% auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.contact-form-left {
  width: 50%;
  background-image: url(./contact-img.jpg);
  background-color: var(--dark-text-color);
  color: var(--white);
  filter: grayscale(100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.contact-form-left h2 {
  width: 80%;
  margin: 15% auto;
  font-size: 1.5rem;
  text-align: center;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.7);
}
.contact-form-right {
  width: 50%;
  background-color: var(--highlight-pink);
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: column;
}
.contact-form-right h2 {
  width: 80%;
  margin: 0 auto;
  margin-top: 15%;
  font-size: 1.5rem;
  text-align: center;
}
.contact-form-right p {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  font-size: 1rem;
  text-align: center;
}
.input-fields {
  width: 80%;
  margin: 5% auto;
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
}
.input-fields input {
  padding: 1% 0 3% 1%;
  margin-bottom: 2%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.input-fields textarea {
  padding: 1% 0 20% 1%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.send-btn {
  display: inline-block;
  padding: 0.5em 3em;
  border: 3px solid rgba(251, 252, 250, 0.9);
  background-color: rgba(9, 5, 5, 1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--white);
  margin-top: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  transition: all 0.15s;
  cursor: pointer;
}
.send-btn:hover {
  background-color: var(--white);
  color: var(--dark-text-color);
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .contact-header-container h1 {
    margin-top: 5%;
    font-size: 2.2rem;
  }
  .contact-form {
    margin-top: 5%;
    margin-bottom: 10%;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .contact-form-right {
    width: 100%;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
  }
  .contact-form-right h2 {
    margin-top: 5%;
    font-size: 1.2rem;
  }
  .contact-form-right p {
    font-size: 1rem;
  }
  .contact-form-left {
    width: 100%;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0;
  }
  .contact-form-left h2 {
    margin-top: 6%;
    font-size: 1.2rem;
  }
}
