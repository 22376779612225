.about-section {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  color: var(--dark-text-color);
}
.about-body {
  padding-bottom: 4%;
}
.about-header-container h1 {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
  color: var(--highlight-pink);
  font-size: 3rem;
  text-transform: uppercase;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.about-header-container h2 {
  width: 90%;
  margin: 0 auto;
  font-size: 1.7rem;
}
.about-header-container h3 {
  width: 90%;
  margin: 0 auto;
  font-size: 1.3rem;
}
.name {
  font-size: 2rem;
  color: var(--highlight-pink);
}
.profile {
  width: 22%;
  border-radius: 1rem;
  margin-top: 1%;
  margin-bottom: 1%;
}
.question {
  font-size: 1.5rem;
  width: 60%;
  margin: 0 auto;
  padding-top: 2%;
  padding-bottom: 1%;
  color: var(--highlight-pink);
}
.first-intro-text {
  padding-top: 1.5%;
  text-align: center;
  margin: 0 auto;
  width: 60%;
  font-size: 1rem;
}
.first-intro-text p {
  padding-bottom: 1%;
}
.intro-text {
  text-align: center;
  margin: 0 auto;
  width: 60%;
  font-size: 1rem;
}
.intro-text p {
  padding-bottom: 1%;
}
.intro-text a {
  color: var(--highlight-orange);
  cursor: pointer;
  font-weight: 500;
}
.intro-text a:hover {
  opacity: 0.8;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .about-header-container h1 {
    margin-top: 5%;
    font-size: 2.2rem;
  }
  .about-header-container h2 {
    margin-top: 3%;
    font-size: 1.3rem;
  }
  .about-header-container h3 {
    font-size: 1rem;
  }
  .name {
    font-size: 1.3rem;
  }
  .profile {
    width: 50%;
    margin: 2% 0;
  }
  .question {
    font-size: 1.2rem;
    width: 85%;
    padding-bottom: 2%;
  }
  .first-intro-text {
    width: 85%;
    font-size: 0.8rem;
  }
  .first-intro-text p {
    padding-bottom: 1%;
  }
  .intro-text {
    width: 85%;
    font-size: 0.8rem;
  }
  .intro-text p {
    padding-bottom: 1.5%;
  }
  .about-body {
    padding-bottom: 7%;
  }
}
