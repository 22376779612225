.inspiration-section {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.inspiration-header-container h1 {
  margin: 0 auto;
  margin-top: 2%;
  color: var(--highlight-pink);
  font-size: 3rem;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.inspiration-header-container h2 {
  font-size: 1.7rem;
}
.inspiration-text {
  width: 85%;
  margin: 0 auto;
}
.inspiration-text h2 {
  width: 70%;
  padding-top: 3%;
  margin: 0 auto;
  color: var(--dark-text-color);
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .inspiration-header-container h1 {
    margin-top: 5%;
    font-size: 2rem;
  }
  .inspiration-header-container h2 {
    margin-top: 3%;
    font-size: 1.2rem;
  }
}
