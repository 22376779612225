.learn-section {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  background-color: var(--white);
  color: var(--dark-text-color);
}
.learn-header-container a {
  text-decoration: none;
}
.learn-header-container h1 {
  margin: 0 auto;
  margin-top: 2%;
  color: var(--highlight-pink);
  font-size: 3rem;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.topics-intro {
  width: 90%;
  margin: 0 auto;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1%;
  font-size: 1.7rem;
}
.contests-intro {
  width: 90%;
  margin: 0 auto;
  margin-top: 3%;
  padding: 1%;
  font-size: 1.7rem;
}
.contests p {
  width: 80%;
  margin: 0 auto;
  padding: 0.5%;
}
.developer-container {
  margin: 0 auto;
  padding: 1%;
  margin-bottom: 2%;
}
.developer {
  width: 40%;
  margin: 0 auto;
  border-radius: 1rem;
  display: block;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .learn-header-container h1 {
    margin-top: 5%;
    font-size: 2.2rem;
  }
  .topics-intro {
    margin-top: 3%;
    font-size: 1.2rem;
  }
  .contests-intro {
    margin-top: 5%;
    padding: 0.5%;
    font-size: 1.2rem;
  }
  .contests p {
    width: 90%;
    padding: 0.5%;
    font-size: 0.8rem;
  }
}
