.blog-page {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 2%;
  text-align: center;
}
.blog-header-container h1 {
  width: 90%;
  margin: 0 auto;
  margin-top: 2%;
  color: var(--highlight-pink);
  font-size: 3rem;
  text-transform: uppercase;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}
.blog-header-container h2 {
  font-size: 1.7rem;
}
/*Small devices*/
@media screen and (max-width: 640px) {
  .blog-header-container h1 {
    margin-top: 5%;
    font-size: 2rem;
  }
  .blog-header-container h2 {
    margin-top: 3%;
    font-size: 1.2rem;
  }
}
